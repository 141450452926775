<style scoped>
.cms-inner-page{
  padding:70px 0
}
.contact-title{
  font-size:28px;
  text-align:center;
  color:#04092c;
  margin-bottom:30px;
  font-weight:600
}
.contact-content{
  width:60%;
  margin:0 auto;
  text-align:center
}
.contact-content p,.contact-content ul li{
  font-family: 'Heebo', sans-serif;
  color: #04092c;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;
}
.contact-content p b{
  color: #04092c;
  letter-spacing: .5px;
}
.contact-content ul{
  padding-left: 0px;
}
.contact-content ul li b{
  color:#04092c;
  letter-spacing:.5px
}
.contact-content ul li a{
  margin-left:5px;
  color:#ea3708 !important;
  font-weight:700
}
.innerpage-banner{
  height:150px;
  position: relative;
}
.innerpage-banner:after{
  content:'';
  position:absolute;
  top:0;
  bottom:0;
  left:0;
  display:inline-block;
  background-color:rgb(0 0 0/45%);
  width:100%;
  z-index:2;
}
.innerpage-banner img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.innerpage-banner .section-header{
  -webkit-box-align:center;
  -ms-flex-align:center;
  align-items:center;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -ms-flex:1;
  flex:1;
  height:100%;
  max-width:100%;
  position:absolute;
  width:100%;
  z-index:3;
  justify-content:center;
  top: 50%;
  transform: translateY(-50%);
  padding: 0 10px;
}
.innerpage-banner .section-header h2{
  color:#fff;
  font-family:'Heebo', sans-serif !important;
  font-weight:700;
  font-size: 36px;
  text-align: center;
  margin-bottom: 0px;
}

@media (min-width: 1024px){
  .innerpage-banner{
    height:180px
  }
}
@media (max-width: 1280px){
  .innerpage-banner .container{
    max-width:100%
  }
}
@media (max-width: 1024px) {
  .cms-inner-page{
    padding:50px 0
  }
  .contact-title{
    font-size:24px;
    margin-bottom:15px
  }
  .contact-content{
    width:80%
  }
  .contact-content p,.contact-content ul li{
    font-size: 14px;
  }
  .innerpage-banner .section-header h2{
    font-size:32px
  }
}
@media (max-width: 768px) {
  .contact-title{
    font-size:20px
  }
  .contact-content{
    width:100%
  }
  .contact-content p,.contact-content ul li{
    font-size: 13px;
    line-height: 20px;
  }
  .innerpage-banner .section-header h2{
    font-size:24px
  }
}
@media (max-width: 767px) {
  .cms-inner-page{
    padding:35px 0
  }
  .innerpage-banner .container{
    max-width:100%
  }
}

.cms-inner-page{
  padding:70px 0
}
.page-not-found .page-title,
.page-not-found .page-content{
  text-align: center;
}
.page-not-found .page-title{
  font-size: 52px;
  color: #04092c;
  font-weight: 700;
  margin-bottom: 40px;
}
.page-not-found .page-content p{
  font-size: 18px;
}
.btn-wrapper{
  display: flex;
  justify-content: center;
}
.home-page-link{
  background-color: rgb(240 129 0/20%);
  color: #f08100 !important;
  padding: 8px 12px;
  font-family: 'Heebo', sans-serif;
  font-weight: 600;
  display: block;
  border-radius: 6px;
  font-size: 15px;
  line-height: normal;
  transition: all .3s ease-in-out 0s;
}
@media (max-width: 1366px) {
  .page-not-found .page-title{
    font-size: 46px;
    margin-bottom: 30px;
  }
}
@media (max-width: 1024px) {
  .cms-inner-page{
    padding:50px 0
  }
  .page-not-found .page-title{
    font-size: 38px;
    margin-bottom: 25px;
  }
}
@media (max-width: 767px) {
  .cms-inner-page{
    padding:35px 0
  }
  .page-not-found .page-title{
    font-size: 32px;
  }
  .page-not-found .page-content p{
    font-size: 16px;
  }
}
@media (max-width: 480px) {
  .page-not-found .page-title{
    font-size: 26px;
  }
}
</style>
<template>
  <section class="innerpage-banner contact-page">
    <v-lazy-image src="../../../images/banner.webp" alt="image" width="1920" height="235"></v-lazy-image>
    <div class="section-header">
      <h2>Thank you!</h2>
      <BannerSubText />
    </div>
  </section>
  <section class="page-not-found cms-inner-page">
    <div class="container">
      <div class="page-content">
        <p>We are processing your payment</p>
        <p>We will get in touch with you soon by sending email on provided email address.</p><br>
        <p><b>Thank you for choosing Lit Seats.</b></p><br>
        <div class="btn-wrapper">
          <router-link :to="`/`" class="home-page-link">Go to Homepage</router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import VLazyImage from 'v-lazy-image'
import BannerSubText from './../../components/layouts/BannerSubText.vue'

export default {
  name: 'Contact',
  components: {
    VLazyImage,
    BannerSubText
  },
  beforeCreate () {
    document.title = 'Thank you | ' + process.env.VUE_APP_NAME
  },
  data () {
    return {
      appName: process.env.VUE_APP_NAME
    }
  }
}
</script>
